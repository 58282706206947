footer {
  max-width: 80vw;
  width: 100%;
  padding: 4rem 2rem 2rem;
  margin-inline: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & .logo {
    display: flex;
    align-items: center;
    gap: .75rem;

    font: 700 1.25rem/1.3 "Syne", sans-serif;

    & img {
      width: 4.5rem;
    }
  }

  & nav {
    display: flex;
    gap: 2rem;

    & a {
      width: fit-content;
      position: relative;

      &:hover::after {
        opacity: 1;
        transform: scaleX(1);
      }
    }

    & a::after {
      content: "";
      width: 100%;
      height: 2px;
      background-color: var(--snitap-sky-mid);
    }
  }

  & .social-links {
    display: flex;
    align-items: center;

    & a {
      width: 2.5rem;
      height: 2.5rem;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 5rem;

      position: relative;

      &:hover::before {
        scale: 1;
      }

      & img {
        width: 1.5rem;
        height: auto;
      }

      &::before {
        content: "";

        width: inherit;
        height: inherit;
        border-radius: inherit;


        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        scale: 0;
        transition: scale 200ms;
      }
    }
  }
}

@media (width <=28em) {
  footer {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 3rem;
    width: calc(100% - 4rem);
    max-width: calc(100% - 2rem) ;
    & nav {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}