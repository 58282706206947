:root {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #5a5a5a;
  color: white;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {  
  /* max-width: 80rem;
  width: 100%;
  margin-inline: auto; */
  min-height: 100vh;
  max-width: 80vw;
  margin-inline: auto;
}
.no-container {  
  min-height: 0vh;
}

section {
  min-height: 100vh;
}

.opacity {
  background-color: #00000062;
}

.full {
  width: 100vw;
}
@media (width<=28em) {
  .container {
    margin-inline: 2%;
  }
}