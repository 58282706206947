.calendar {
  align-items: center;
  text-align: center;
}

.calendar-item {
  display: flex;
  background-color: #4a4a4a;
  border-radius: 10px;
  justify-content: space-between;
  margin: 1rem;
  align-items: center;

  &>div {
    margin-left: 1rem;
  }

  & .confirm {
    width: 9.375rem;
    background-color: green;
    text-align: center;
    margin: 1rem;
    margin-right: 1rem;
    align-content: center;
    border-radius: 5px;

    & img {
      width: 4.5rem;
      padding-top: 0.8rem;
    }
  }
}

@media (width<=28em) {
  .calendar-item {
    display: flex;
    background-color: #4a4a4a;
    border-radius: 5px;
    justify-content: space-between;
    margin: 1rem;
    align-items: center;

    & .confirm {
      width: 4.5rem;
      background-color: green;
      text-align: center;
      margin-right: 1rem;
      align-content: center;
      border-radius: 5px;

      & img {
        width: 4.5rem;
        padding-top: 0.8rem;
      }

      & p {
        font-size: 0.625rem;
      }
    }
  }
}