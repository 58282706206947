.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.25rem;

  & div {
    display: flex;
    align-items: center;
    gap: 1rem;

    & img {
      width: 40px;
      height: 40px;
    }
  }

  nav {
    display: flex;
    gap: 1rem;
  }
}

@media (width<=28em) {
  .header {
    & nav {
      & a {
        font-size: 0.625rem;
      }
    }
  }
}