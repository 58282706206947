#post-natal {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	&>.info {
		width: 50%;
		padding: 30px;
		justify-content: "space-between";
		padding-bottom: 3rem;

		&>h1 {
			font-size: 2rem;
			padding-bottom: 1.5rem;
		}

		&>h3 {
			padding-bottom: 2rem;
			font-size: 1.56rem;
		}
	}

	&>.image {
		width: 50%;

		&>img {
			width: 100%;
		}
	}

}

@media (width<=28rem) {
	#post-natal {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;

		&>.info {
			width: 90%;

		}
		&>.image {
			width: 100%;
		
			&>img {
				width: 100%;
			}
		}
	}
}