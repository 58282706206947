.image-about {
  display: flex;
  width: 90vw;
  max-width: 100vw;
  height: 60vh;
  object-fit: cover;
  margin-inline: auto;
}

.about-us {
  display: flex;

  &>img {
    width: 50%;
    object-fit: cover;
  }

  & .info-about {
    width: 50%;

    &>p {
      font-size: 2rem;
    }
  }
}

#about {
  min-height: 75vh;
}

@media (width<=28em) {
  .image-about {
    height: 25vh;
    width: 95%;
    max-width: 100vw;
    object-fit: cover;
  }

  .about-us {
    display: flex;
    max-width: 80rem;
    width: 100%;
    margin-inline: auto;

    &>img {
      display: none;
    }

    & .info-about {
      margin-inline: auto;
      width: 90%;
    }
  }
}