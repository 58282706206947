.participant {
  align-items: center;
  text-align: center;
}

.participant-header {
  padding: 3rem;
}

.container-participants {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "1 2 3 4 5";
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: start;


  &.img {
    width: 100%;
  }

}

.container-participant {
  text-align: start;
  border-radius: 1rem;
  background-color: #4a4a4a;
  padding: 1rem;
  height: 25rem;

  &>div {

    &>span {
      
      &>a {
        display: flex;
        gap: .5rem;
        align-items: center;
      }
    }
  }

  &>img {
    height: 80%;
    width: 100%;
    object-fit: cover;
    transition: scale 500ms;
  }

  &:hover {
    &>img {
      scale: 1.05;
    }
  }

}

@media (width<=28em) {

  .participant {
    align-items: center;
    text-align: center;
  }


  .container-participants {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "1"
      "2"
      "3"
      "4";
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: start;


    &.img {
      width: 100%;
    }

  }

  .container-participant {
    text-align: start;
    border-radius: 1rem;
    background-color: #4a4a4a;
    padding: 1rem;
    height: 34rem;

    &>div {

      &>span {
        display: flex;
        gap: .5rem;
        align-items: center;
      }
    }

    &>img {
      height: 80%;
      width: 100%;
      object-fit: cover;
    }

  }
}